import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { graphql } from "gatsby"
import DietLayout from "../components/DietLayout"

export const query = graphql`
  {
    file(name: { eq: "diet1" }) {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

var planWegeFish = {
  title: "o Niskim Indeksie Glikemicznym.",
  menu: [
    {
      name: "Śniadanie",
      hour: "7:00",
      description: "Owsianka jagodowo-jogurtowa",
    },
    {
      name: "Drugie Śniadanie",
      hour: "10:00",
      description: "Fit deser a'la bounty",
    },
    {
      name: "Obiad",
      hour: "13:00",
      description: "Cukinia faszerowana",
    },
    {
      name: "Podwieczorek",
      hour: "17:00",
      description: "Zielona szakszuka",
    },
    {
      name: "Kolacja",
      hour: "19:30",
      description: "Sałatka z jajkiem i młodymi ziemniakami",
    },
  ],
}

const PlanLowIGPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <Seo title="Diata O NISKIM INDEKSIE GLIKEMICZNYM" />
      <SmallHeroSection />
      <DecorativeSection />

      <DietLayout diet={planWegeFish} image={data.file}>
        Jadłospis został stworzony dla osób borykających się z cukrzycą,
        insulinoopornością, lub tych, którym została zalecona dieta o niskim
        indeksie glikemicznym. Pełnowartościowa dieta dla tych, którzy mają
        problem z utrzymaniem prawidłowego poziomu cukru we krwi.
      </DietLayout>
    </Layout>
  )
}

export default PlanLowIGPage
